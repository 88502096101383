import React, { Component } from "react";
import "./styles.css";

class Answer extends Component {
  render() {
    return <div />;
  }
}

export default Answer;
